import React from 'react'  
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import List from '../components/list'
import { Badge, CardColumns } from 'reactstrap'
import Link from 'gatsby-link'
import GigCard from '../components/card'
import Helmet from 'react-helmet'

const TagTemplate = ({ data }) => {
  const tag = data.strapiTags;
  const artists = tag.artists;
  const gigs = data.allStrapiGigs.edges;

  return (
  <Layout>
  <Helmet
          title={`${tag.name} | Laz's Bootlegs`}
          meta={[
            { property: 'og:description', content: `Artists tagged with ${tag.name} in Larry's collection of bootlegs` },
            { name: 'keywords', content: `music, melbourne, ${tag.name}` },
            { property: 'og:title', content: `${tag.name} | Laz's Bootlegs`},
          ]}
        >
</Helmet>
    <h2>Artists tagged with '{tag.name}'</h2>
    {artists.map((item , i) => (
              <Badge className="pill" key={i} tag={Link} to={`/artist/${item.slug}`} color="secondary">{item.name}</Badge>
        ))}
    <h2>Related gigs</h2>
    <CardColumns>
        {gigs.map((item , i) => (
              <GigCard key={i} poster={item.node.poster} title={item.node.title} date={item.node.date} slug={item.node.slug} description={item.node.description} venue={item.node.venue} />
        ))}
      </CardColumns>
  </Layout>
  )}

export default TagTemplate

export const query = graphql`  
query TagTemplate($slug: String!,  $id: String!) {
  strapiTags(slug: {eq: $slug}) {
    name
    artists {
      name
      slug
    }
  }
  allStrapiGigs(
    filter:
      { artists:
          { elemMatch: {
            tags: {
              in: [$id] }
              }
            }
          },
    sort: {fields: [date], order: DESC}
    )
    {
    edges {
      node {
        slug
        poster {
          childImageSharp { fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
          }
        }
        date(formatString: "DD MMMM YYYY")
        id
        description
        title
        slug
        venue {
                slug
                name
              }
      }
    }
  }
}
`
